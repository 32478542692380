import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import "./OsintText.css";

const TIMEOUTS = 150;
const WAITING = 2000;

function OsintText() {
    const [textIndex, setTextIndex] = useState(0);
    const [displayText, setDisplayText] = useState("");
    const [typing, setTyping] = useState(true);

    const { t, i18n } = useTranslation();

    const texts = [t("welcome-text-1"), t("welcome-text-2"), t("welcome-text-3")];

    useEffect(() => {
        let typingTimeout;
        if (typing) {
          const currentText = texts[textIndex];
          const currentLength = displayText.length;
    
          if (currentLength < currentText.length) {
            typingTimeout = setTimeout(() => {
              setDisplayText((prevDisplayText) =>
                currentText.slice(0, prevDisplayText.length + 1)
              );
            }, TIMEOUTS);
          } else {
            setTyping(false);
            setTimeout(() => {
              setTyping(true);
              if(textIndex + 1 === texts.length){
                setTextIndex(0);
              }else {
                setTextIndex(textIndex + 1);
              }
              setDisplayText("");
            }, WAITING);
          }
        }
        return () => clearTimeout(typingTimeout);
      }, [displayText, typing, textIndex]);

    return (
        <div className='typeing-container'>
            <div className='type oswald-font700'>{displayText}<span className="cursor">|</span></div>
        </div>
    );
}

export default OsintText;
