import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';

import { LanguageProvider } from './Locales/LanguageContext';
import './Locales/i18n';

import './index.css';
import './properties.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>
);