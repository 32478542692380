import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import "./SearchBox.css";

function SearchBox({setIsSearched}) {
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const { t, i18n } = useTranslation();

    const handleLoading = () => {
        setLoading(true);
        setIsSearched(false);
        setTimeout(() => {
          setLoading(false);
          setIsSearched(true);
        }, 1500);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleLoading();
        }
    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className="home-search">
            <input
                placeholder={t("search")}
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                value={searchTerm}
            />
            {loading ? (
                <div className="search-spinner">
                    <i className="fa fa-spinner"></i>
                </div>
            ) : (
                <button onClick={handleLoading}>
                    <i className="fa fa-search"></i>
                </button>
            )
            }
        </div>
    );
}

export default SearchBox;
