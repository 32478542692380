import React, { useState, useEffect } from "react";

import "./Home.css";
import OsintText from "./FirstState/OsintText";
import SearchBox from "./FirstState/SearchBox";

function Home() {
    const [isSearched, setIsSearched] = useState(false);

    return (
        <div className="Home">
            <div className="home-inside">
                <div className="osint-container">
                    <OsintText />
                </div>
                <div className="searchbox-container">
                    <SearchBox setIsSearched={setIsSearched} />
                    {isSearched && (
                        <div class="down-container">
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                            <div class="chevron"></div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;
